import React, { useState, useEffect, useMemo } from 'react';
import { X, Search, Check } from 'lucide-react';

const FilterModal = ({
  isOpen,
  onClose,
  documentLabels,
  documentFilters,
  onFilterChange,
  countChecked
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  
  // Handle click outside to close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains('modal-overlay')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Normalize string for search (remove accents and make case insensitive)
  const normalizeString = (str) => {
    return str.normalize("NFD")
             .replace(/[\u0300-\u036f]/g, "")
             .toLowerCase();
  };

  // Filter and sort items based on search and selection
  const filteredAndSortedItems = useMemo(() => {
    const normalizedSearch = normalizeString(searchTerm);
    
    return Object.entries(documentLabels)
      .filter(([key, label]) => {
        if (!searchTerm) return true;
        return normalizeString(label).includes(normalizedSearch);
      })
      .sort((a, b) => {
        // Sort by selected status first
        const aSelected = documentFilters[a[0]] || false;
        const bSelected = documentFilters[b[0]] || false;
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;
        return 0;
      });
  }, [documentLabels, documentFilters, searchTerm]);

  if (!isOpen) return null;
  const handleFilterChange = (event) => {
    onFilterChange(event);
    setSearchTerm(''); // Clear search after selection
  };
  return (
    <div className="modal-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.93)', 
      backdropFilter: 'blur(4px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000
    }}>
      <div className="modal-content" style={{
        backgroundColor: 'var(--theme-bg-color)',
        borderRadius: '14px',
        padding: '20px',
        width: '90%',
        maxWidth: '600px',
        maxHeight: '80vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
      }}>
        {/* Header */}
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          marginBottom: '20px'
        }}>
          <h3 style={{ margin: 0 }}>Filtro de Leyes ({countChecked()} seleccionados)</h3>
          <button
            onClick={onClose}
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              padding: '5px'
            }}
          >
            <X size={20} />
          </button>
        </div>

        {/* Search Bar */}
        <div style={{
          position: 'relative',
          marginBottom: '20px'
        }}>
          <div style={{
            position: 'absolute',
            left: '10px',
            top: '50%',
            transform: 'translateY(-50%)'
          }}>
            <Search size={20} />
          </div>
          <input
            type="text"
            placeholder="Buscar leyes..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: '100%',
              padding: '10px 10px 10px 40px',
              borderRadius: '8px',
              border: '1px solid var(--border-color)',
              backgroundColor: 'var(--theme-bg-color)',
              color: 'var(--theme-color)'
            }}
          />
        </div>

        {/* Filters List */}
        <div style={{
          overflowY: 'auto',
          padding: '10px',
          flex: 1,
          marginBottom: '20px'
        }}>
          {filteredAndSortedItems.map(([key, label]) => (
            <div 
              key={key} 
              className="checkbox-wrapper"
              style={{
                padding: '8px',
                marginBottom: '8px',
                borderRadius: '8px',
                backgroundColor: documentFilters[key] ? 'rgba(59, 240, 131, 0.1)' : 'transparent'
              }}
            >
              <input
                type="checkbox"
                className="checkbox"
                id={key}
                name={key}
                checked={documentFilters[key] || false}
                onChange={handleFilterChange}
              />
              <label className="labelito" htmlFor={key}>{label}</label>
            </div>
          ))}
        </div>
        <button
          onClick={onClose}
          className="content-button status-button"
          style={{
            background: '#3bf083',
            color: '#000',
            padding: '12px',
            borderRadius: '8px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '0.95rem',
            fontWeight: '500',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            transition: 'transform 0.2s ease, background 0.2s ease'
          }}
          onMouseOver={(e) => e.currentTarget.style.transform = 'translateY(-1px)'}
          onMouseOut={(e) => e.currentTarget.style.transform = 'translateY(0)'}
        >
          <Check size={18} />
          Aceptar
        </button>
      </div>
    </div>
  );
};

export default FilterModal;