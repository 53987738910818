import React, { useState } from 'react';
import { Copy, Share2, FileDown, MoreHorizontal, X,Trash2 } from 'lucide-react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { asBlob } from 'html-docx-js-typescript';
import { UserAuth } from '../../context/AuthContext';
const ActionButtons = ({ question, markdownToWhatsApp, onQuestionDeleted }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const { token } = UserAuth();
  const handleDelete = async () => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar esta pregunta?')) {
      return;
    }

    setIsDeleting(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_GUARDAR_PREGUNTA}/api/eliminar/${question.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
      
      if (data.success) {
        // You'll need to implement this handler in the parent component
        onQuestionDeleted(question.id);
      } else {
        throw new Error(data.error || 'Error al eliminar la pregunta');
      }
    } catch (error) {
      console.error('Error deleting question:', error);
      alert('No se pudo eliminar la pregunta');
    } finally {
      setIsDeleting(false);
    }
  };
  const simplifyMarkup = (markup) => {
    return markup
      .replace(/\*\*(.*?)\*\*/g, '$1')  // Remove bold formatting
      .replace(/\*(.*?)\*/g, '$1')      // Remove italic formatting
      .replace(/```(.*?)```/g, '$1')    // Remove code blocks but keep content
      .replace(/`(.*?)`/g, '$1')        // Remove inline code formatting
      .replace(/^\s*[-*+]\s/gm, '• ')   // Simplify list items
      .replace(/^\s*#{1,6}\s*/gm, '')   // Remove headers
      .replace(/\n{3,}/g, '\n\n')       // Replace multiple newlines with two
      .trim();                          // Trim leading and trailing whitespace
  };
  const actions = [
    
    {
      icon: <Copy size={18} />,
      label: 'Copiar texto',
      action: () => {
        const simplifiedText = simplifyMarkup(question.content);
        navigator.clipboard.writeText(simplifiedText)
          .then(() => {
            setCopyStatus('¡Copiado!');
            setTimeout(() => setCopyStatus(''), 2000);
            setIsMenuOpen(false);
          });
      }
    },
    {
      icon: <Share2 size={18} />,
      label: 'Compartir vía WhatsApp',
      action: () => {
        const text = question.content.replace(/<br\s*\/?>/gi, '\n')
          .replace(/<div\s*\/?>/gi, '\n')
          .replace(/<\/div\s*\/?>/gi, '\n')
          .replace(/&nbsp;/g, ' ');
        const whatsappText = markdownToWhatsApp("*" + question.title + "*:\n" + text);
        const encodedText = encodeURIComponent(whatsappText + "\n\nDesbloquea el mundo del derecho con Temis: https://www.temisgpt.io");
        window.open(`https://wa.me/?text=${encodedText}`, '_blank', 'noopener,noreferrer');
        setIsMenuOpen(false);
      }
    },
    {
      icon: <FileDown size={18} />,
      label: 'Descargar como Word',
      action: () => {
        const htmlContent = `<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>${DOMPurify.sanitize(marked.parse(question.content))}</body></html>`;
        asBlob(htmlContent).then(blob => {
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = question.title + '.docx';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);
        });
        setIsMenuOpen(false);
      },
      mobileWarning: "Descargar Word solamente funciona en PC debido al formato del documento generado"
    },
    {
      icon: <Trash2 size={18} />,
      label: 'Eliminar pregunta',
      action: handleDelete,
      className: 'delete-action' // For styling
    }
  ];

  return (
    <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        style={{
          background: 'rgba(59, 240, 131, 0.1)',
          border: 'none',
          borderRadius: '8px',
          padding: '10px',
          cursor: 'pointer',
          color: '#3bf083',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          transition: 'all 0.2s ease',
          fontSize: '20px'
        }}
      >
        {isMenuOpen ? <X size={20} /> : <>
      <MoreHorizontal size={20} />
      <span>Opciones</span>
    </>}
      </button>

      {isMenuOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            right: '0',
            marginTop: '8px',
            background: 'black',
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '8px',
            minWidth: '200px',
            zIndex: 1000
          }}
        >
          {actions.map((action, index) => (
            <div key={index}>
              <button
                onClick={action.action}
                disabled={action.className === 'delete-action' && isDeleting}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  width: '100%',
                  padding: '12px',
                  border: 'none',
                  fontSize: '22px',
                  background: 'none',
                  color: action.className === 'delete-action' ? '#ff4d4d' : 'var(--theme-color)',
                  cursor: 'pointer',
                  borderRadius: '8px',
                  transition: 'all 0.2s ease'
                }}
                onMouseEnter={(e) => {
                  if (!isDeleting) {
                    e.currentTarget.style.background = action.className === 'delete-action' 
                      ? 'rgba(255, 77, 77, 0.1)' 
                      : 'rgba(59, 240, 131, 0.1)';
                  }
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.background = 'none';
                }}
              >
                <span style={{ 
                  color: action.className === 'delete-action' ? '#ff4d4d' : '#3bf083' 
                   }}>{action.icon}
                   </span>
                   {isDeleting && action.className === 'delete-action' ? 'Eliminando...' : action.label}
              </button>
              {action.mobileWarning && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && (
                <p style={{
                  margin: '0',
                  padding: '4px 12px',
                  fontSize: '0.8rem',
                  color: 'var(--inactive-color)'
                }}>
                  {action.mobileWarning}
                </p>
              )}
            </div>
          ))}
        </div>
      )}
      
      {copyStatus && (
        <div style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          background: '#3bf083',
          color: '#000',
          padding: '8px 16px',
          borderRadius: '8px',
          animation: 'fadeIn 0.3s ease'
        }}>
          {copyStatus}
        </div>
      )}
    </div>
  );
};

export default ActionButtons;