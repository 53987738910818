import {React, useState, useEffect} from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PopupComponentAdvertise from './pop/advertise';
import {Sparkles,ArrowRight, Package, Zap, Crown, Heart, MessageSquare, ExternalLink, ChevronDown } from 'lucide-react';

const Header = ({ isFilterVisible, countChecked, onOpenModal,  handleFilterToggle}) => {
    const navigate = useNavigate();
    const {user, token} = UserAuth();
    const [isProUser, setisPro] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(true);
    const [elplan, setelplan] = useState('');
    const [isFeedbackExpanded, setIsFeedbackExpanded] = useState(false);
    const iconSize = 24; // Change this value to whatever size you want
    const [isFilterExpanded, setIsFilterExpanded] = useState(false);
    const planColors = {
        sabio: '#3bf083',
        experto: '#9b6dff',
        free: '#3a6df0'
    };
    
    const getPlanIcon = () => {
        const props = {
            size: iconSize,
            strokeWidth: 2  // Optional: adjust stroke width if needed
        };

        switch (elplan) {
            case 'sabio':
                return <Crown {...props} color={planColors.sabio} />;
            case 'experto':
                return <Zap {...props} color={planColors.experto} />;
            case 'free':
            default:
                return <Package {...props} color={planColors.free} />;
        }
    };

    const getPlanName = () => {
        switch (elplan) {
            case 'sabio':
                return 'Plan Sabio';
            case 'experto':
                return 'Plan Experto';
            case 'free':
            default:
                return 'Plan Gratuito';
        }
    };
    const getPlanColor =() =>
    {
        switch(elplan)
        {
            case 'sabio':
                return planColors.sabio;
            case 'experto':
                return planColors.experto;
            case 'free':
            default:
                return planColors.free;
        }
    }

    const account = async () => {
        navigate('/account/new');
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user) {
                    const idTokenResult = await user.getIdTokenResult();
                    setelplan(idTokenResult.claims.plan || 'free');
                    setisPro(idTokenResult.claims.plan !== undefined && idTokenResult.claims.plan !== 'free');
                }
            } catch (error) {
                console.error("Error fetching subscription status:", error);
                setelplan('free');
            }
        };
    
        fetchData();
    }, [user]);

    return (
        <>
        <div className="tooltip">
            <div className="content-section-title" style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                {getPlanIcon()}
                    <span style={{ 
                        color: getPlanColor(),
                        fontWeight: 'bold'
                    }}>
                        
                        {getPlanName()}
                    </span>
                    {elplan === 'free'&& <p className="content-section-disclaimer">5 preguntas diarias</p>}
                    
                </div>
                <a href="#" style={{color: "rgba(0, 255, 0)"}} onClick={account}> 
                {!isProUser && (
    <div 
        onClick={account}
        style={{
            position: 'relative',
            background: 'linear-gradient(135deg, rgba(59, 240, 131, 0.1), rgba(155, 109, 255, 0.1))',
            padding: '0px',
            borderRadius: '12px',
            margin: '15px auto',
            maxWidth: '300px',
            cursor: 'pointer',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        }}
        onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(59, 240, 131, 0.15)';
        }}
        onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.boxShadow = 'none';
        }}
    >
        <div style={{
            background: 'var(--theme-bg-color)',
            borderRadius: '10px',
            padding: '12px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
            position: 'relative',
            overflow: 'hidden'
        }}>
            <Sparkles 
                size={18} 
                style={{
                    color: '#3bf083',
                    animation: 'sparkle 1.5s ease-in-out infinite'
                }}
            />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                
                <span style={{
                    color: '#3bf083',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>
                    Explorar Planes Premium
                    <ArrowRight size={16} />
                </span>
            </div>
        </div>
    </div>
)}
                </a>
                
                {/* Updated Feedback Section */}
                <div style={{ 
                    background: 'rgba(59, 240, 131, 0.1)', 
                    padding: '15px',
                    borderRadius: '8px',
                    margin: '0px 0',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease'
                }}>
                    <div 
                        onClick={() => setIsFeedbackExpanded(!isFeedbackExpanded)}
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            marginBottom: isFeedbackExpanded ? '8px' : '0'
                        }}
                    >
                        <Heart size={20} style={{ color: '#3bf083' }} />
                        {isFeedbackExpanded && <span style={{ 
                            fontWeight: 'bold',
                            color: '#3bf083',
                            fontSize: '13px'

                        }}>
                            ¡Ayúdanos a mejorar Temis!
                        </span>}
                        <ChevronDown 
                            size={18} 
                            style={{ 
                                color: '#3bf083',
                                transform: isFeedbackExpanded ? 'rotate(180deg)' : 'rotate(0)',
                                transition: 'transform 0.3s ease'
                            }} 
                        />
                    </div>
                    
                    <div style={{ 
                        maxHeight: isFeedbackExpanded ? '200px' : '0',
                        overflow: 'hidden',
                        transition: 'max-height 0.3s ease',
                        opacity: isFeedbackExpanded ? 1 : 0,
                        transform: `translateY(${isFeedbackExpanded ? '0' : '-10px'})`,
                        transition: 'all 0.3s ease'
                    }}>
                        <div style={{ 
                            fontSize: '0.9rem',
                            color: 'var(--theme-color)',
                            marginBottom: '12px',
                            lineHeight: '1.4',
                            paddingTop: '12px'
                        }}>
                            <MessageSquare 
                                size={16} 
                                style={{ 
                                    verticalAlign: 'middle', 
                                    marginRight: '6px',
                                    color: 'var(--inactive-color)'
                                }} 
                            />
                            Queremos saber qué documentos y características te gustaría ver en nuestra plataforma.
                        </div>

                        <a 
                            href="https://forms.gle/1ajtPSG784ei4xPw9" 
                            target="_blank" 
                            rel="noreferrer"
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: '6px',
                                background: '#3bf083',
                                color: '#000',
                                padding: '8px 16px',
                                borderRadius: '6px',
                                textDecoration: 'none',
                                fontSize: '0.9rem',
                                fontWeight: '500',
                                transition: 'all 0.2s ease'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.transform = 'translateY(-1px)'}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'translateY(0)'}
                        >
                            Dar Feedback
                            <ExternalLink size={16} />
                        </a>
                    </div>
                </div>
                
                
                <div style={{ 
    background: 'rgba(59, 240, 131, 0.1)', 
    padding: '15px',
    borderRadius: '8px',
    margin: '10px 0',
    cursor: 'pointer',
    transition: 'all 0.3s ease'
}}>
    
    <div 
        onClick={() => setIsFilterExpanded(!isFilterExpanded)}
        style={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px'
        }}
    >
        {isFilterVisible ? (
            <p className="filter-status-link">
                Busqueda Filtrada Activada
                <span className="notification-number">{countChecked()}</span> 
            </p>
        ) : (
            <span>Busqueda Filtrada Desactivada</span>
        )}
        <ChevronDown 
            size={18} 
            style={{ 
                color: '#3bf083',
                transform: isFilterExpanded ? 'rotate(180deg)' : 'rotate(0)',
                transition: 'transform 0.3s ease'
            }} 
        />
    </div>
    
    {/* Expanded Content */}
    <div style={{ 
        maxHeight: isFilterExpanded ? '200px' : '0',
        overflow: 'hidden',
        transition: 'max-height 0.3s ease',
        opacity: isFilterExpanded ? 1 : 0,
        transform: `translateY(${isFilterExpanded ? '0' : '-10px'})`,
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
            marginTop: '10px'
        }}>
            <label className="switch">
                <input type="checkbox" checked={isFilterVisible} onChange={handleFilterToggle} />
                <span className="slider round"></span>
            </label>
            
            {isFilterVisible && (
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onOpenModal(); // We'll add this prop next
                    }}
                    className="content-button status-button"
                    style={{
                        background: '#3bf083',
                        color: '#000',
                        padding: '8px 16px',
                        borderRadius: '6px',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: '0.9rem',
                        fontWeight: '500'
                    }}
                >
                    Seleccionar Leyes
                </button>
            )}
        </div>
    </div>
</div>
            </div>
        </div>
        </>
    );
};

export default Header;