import React from 'react';

const AlertPopup = ({ title, message, onClose }) => {
  const modifiedMessage = message.replace(/(([-.])|(🚀))/g, '<br />$1');
  return (
    <div className="pop-up visible">
      <div className="pop-up__title">{title}
      <svg onClick={onClose} className="close" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  <circle cx="12" cy="12" r="10"></circle>
  <path d="M15 9l-6 6M9 9l6 6"></path>
</svg>
      </div>
      <div className="pop-up__subtitle" dangerouslySetInnerHTML={{ __html: modifiedMessage }}></div>
      <div className="content-button-wrapper">
        <button onClick={onClose} className="content-button status-button open close">Close</button>
      </div>
    </div>
  );
};


export default AlertPopup;
