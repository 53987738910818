// App.js
import './App.css';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import AccountEngine from './components/AccountEngine2';
import Politica from './components/Politica';
import Account from './components/Account';
import LandingPage from './components/landingpage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import { SidebarProvider } from './components/layout/context/SidebarContext';
import ProtectedRoute from './components/ProtectedRoute';
import Manuel from './components/Manual';
import PasswordReset from './components/auth/reset';
import ProtectedLayout from './components/layout/ProtectedLayout';
import NewComponent from './components/layout/components/NewComponent';
import Manuellayout from './components/Manual2';
import Header from './components/Location';
import SavedQuestions from './components/saved_questions';

function App() {
  return (
    <Router>
      <div className="App">
      
        <AuthContextProvider>
          <SidebarProvider>
          <Header />
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/reset-password" element={<PasswordReset />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/manual" element={<Manuel />} />
              <Route path="/politica" element={<Politica />} />
              <Route path="/cuenta" element={<ProtectedRoute><Account /></ProtectedRoute>} />
              <Route path="/account" element={<ProtectedRoute><ProtectedLayout /></ProtectedRoute>}>
                <Route index element={<AccountEngine />} />
                <Route path="new" element={<NewComponent />} />
                <Route path="Temis" element={<AccountEngine />} />
                <Route path="politica" element={<Politica />} />
                <Route path="manual" element={<Manuellayout />} />
                <Route path="guardado" element={<SavedQuestions />} />
                {/* Add more nested routes here if needed */}
              </Route>
            </Routes>
          </SidebarProvider>
        </AuthContextProvider>
      </div>
    </Router>
  );
}

export default App;
