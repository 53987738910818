import { useLocation } from 'react-router-dom';  // Remove unused imports

function Header() {
  const location = useLocation();
  
  // First, check if we're requesting an XML file
  if (location.pathname.endsWith('.xml')) {
    return null;  // Don't render anything for XML requests
  }
  
  // Function to get clean route name
  const getRouteName = () => {
    const path = location.pathname;
    // Remove leading slash and get the last segment of the path
    const routeName = path.split('/').filter(Boolean).pop() || 'TEMIS';
    // Capitalize first letter and clean up the route 
    if (routeName === "account") return "Temis"
    if (routeName === "new") return "Planes"

    return routeName.charAt(0).toUpperCase() + routeName.slice(1);
  };

  return (
    <div className="header hidden md:block">
      <div className="menu-circle"></div>
      <div className="header-menu">
        <a className="menu-link is-active" href="#0">{getRouteName()}</a>
      </div>
    </div>
  );
}

export default Header;