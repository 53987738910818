import React from 'react';
import imagen from '../images/temis1.png';
import imagen2 from '../images/temis2.png';
import imagen3 from '../images/temis3.png';
import imagen4 from '../images/law22.png';
import telefono from '../images/4crop2.png';
import chavo from '../images/1crop.png';
import senora from '../images/3crop.png';
import telefono_frente from '../images/5.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';

const LandingPage = () => {
    const navigate = useNavigate();

    const politica = () => {
        navigate('/politica');
    };
    const login = () => {
        navigate('/signin');
    };
    const signup = () => {
        navigate('/signup');
    };
    const manuel = () => {
        navigate('/manual');
    };

    return (
        <div className="wrapper">
            {/* Side Navigation */}
            <div className="left-side">
                <div className="side-wrapper">
                    <div className="side-menu">
                        <button onClick={signup} className="content-button status-button open">Registrarse</button>
                        <button onClick={login} className="content-button status-button open">Login</button>
                        <button onClick={manuel} className="content-button status-button open">Manual</button>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="main-container">
                <div className="content-wrapper">
                    {/* Hero Section */}
                    <div className="content-wrapper-header">
                        <div className="content-wrapper-context">
                            <h2>Temis: Asistencia Legal Impulsada por Inteligencia Artificial</h2>
                            <p>
                                Temis es tu mejor opción de <strong>asistencia legal inteligente</strong> en Guatemala.
                                Gracias a nuestra <strong>IA especializada en leyes guatemaltecas</strong>, puedes obtener
                                respuestas rápidas, claras y confiables para cualquier duda legal. ¡Consulta hoy mismo!
                            </p>
                        </div>
                        <img className="content-wrapper-img3" src={imagen4} alt="Temis logo" />
                    </div>

                    {/* Call to Action */}
                    <div className="side-wrapper">
                        <button onClick={login} className="content-button"><h2>Comenzar</h2></button>
                    </div>

                    {/* About Temis */}
                    <section>
                        <h2>¿Qué es Temis?</h2>
                        <div className="apps-card">
                            <div className="app-card">
                                <span>Asistencia Legal Inteligente</span>
                                <div className="app-card__subtext">
                                    Resuelve tus consultas legales al instante. Rápido, preciso y basado en la ley
                                    guatemalteca. Tu asistente legal digital, ¡siempre a tu servicio!
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Why Choose Temis */}
                    <section>
                        <h2>¿Por qué Temis?</h2>
                        <div className="apps-card" style={{ alignItems: "center" }}>
                            <div className="app-card2">
                                <span>Inmediato y Certero</span>
                                <div className="app-card__subtext2">
                                    Temis elimina la necesidad de navegar horas por textos legales, proporcionando
                                    respuestas claras y precisas al instante.
                                </div>
                            </div>
                            <div className="app-card2">
                                <span>Acceso Automatizado al Derecho</span>
                                <div className="app-card__subtext2">
                                    Simplifica la consulta legal con una experiencia intuitiva y automatizada.
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="content-section"></div>
                    {/* Images and Benefits */}
                    <div className="content-wrapper-header">
                        <img className="content-wrapper-img" src={telefono} alt="Consulta en línea con Temis" />
                        <div className="content-wrapper-context">
                            <h2>Obtén Respuestas Legales Instantáneas</h2>
                            <p>
                                Realiza cualquier consulta legal y recibe respuestas claras, precisas y respaldadas por
                                el marco legal de Guatemala. Todo esto desde la comodidad de tu dispositivo.
                            </p>
                        </div>
                    </div>
                    <div className="content-section"></div>
                    <div className="content-wrapper-header" style={{ padding: "0px 40px" }}>
                        <div className="content-wrapper-context">
                            <h2>Optimiza tu Tiempo</h2>
                            <p>
                                Con Temis, evita perder tiempo buscando en documentos legales extensos. Deja que nuestra
                                inteligencia artificial encuentre lo que necesitas en segundos.
                            </p>
                        </div>
                        <img className="content-wrapper-img" src={chavo} alt="Optimiza tu tiempo con Temis" />
                    </div>

                    {/* Additional Call to Action */}
                    <div className='content-section'>
                    <div className="content-section">
                        <h2>¿Listo para comenzar?</h2>
                        <button onClick={login} className="content-button"><h2>Comenzar</h2></button>
                    </div>

                    {/* Manual and Policy Links */}
                    <div className="content-section">
                        <button onClick={politica} className="content-button status-button open">Lea la política de uso aquí</button>
                    </div>
                    <div className="content-section">
                        <button onClick={manuel} className="content-button status-button open">Lea el Manual de Usuario aquí</button>
                    </div>
                    </div>
                    {/* Social Links */}
                    
                    <ul className="social-media-list">
                        <li>
                            <a href="https://twitter.com/temisgpt" target="_blank" rel="noopener noreferrer" className="contact-icon">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/temisgpt/" target="_blank" rel="noopener noreferrer" className="contact-icon">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
