import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../../context/AuthContext';
import { AlertCircle, CheckCircle2, ArrowRight, Package, Zap, Crown, Loader } from 'lucide-react';
import './NewComponent.css';

const NewComponent = () => {
  const { token } = UserAuth();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const LoadingState = () => (
    <div className="loading-banner">
      <Loader className="loading-icon animate-spin" />
      <p>Cargando información de suscripción...</p>
    </div>
  );
  const plans = [
    {
      id: 'test',
      name: 'Plan Gratuito',
      price: 'Q0.00',
      colorClass: 'plan-blue',
      icon: <Package className="plan-icon" />,
      description: 'Ideal para conocer nuestro servicios',
      features: [
        'Acceso a búsquedas básicas',
        'limite de 5 preguntas diarias'
      ]
    },
    {
      id: 'experto',
      name: 'Plan Experto',
      price: 'Q29.99',
      colorClass: 'plan-purple',
      icon: <Zap className="plan-icon" />,
      description: 'Características mejoradas para profesionales',
      features: [
       
        'Preguntas Ilimitadas',
        'Guardar Preguntas',
        'Analisis Intermedio',
        'Contexto limitado de Artículos',
        'Acceso a nuevos Features',
      ],
      popular: true
    },
    {
      id: 'sabio',
      name: 'Plan Sabio',
      price: 'Q79.99',
      colorClass: 'plan-green',
      icon: <Crown className="plan-icon" />,
      description: 'Máximas capacidades para expertos',
      features: [
       
        'Preguntas Ilimitadas',
        'Guardar Preguntas',
        'Analisis Profundo',
        'Ingresa textos más largos',
        'Contexto de Artículos Extenso',
        'Hasta 6 veces más Extenso que el nivel Experto',
        'Acceso a Publicaciones del diario oficial',
        'Acceso a nuevas Funcionalidades y actualizaciones',
        'Soporte 24/7'
      ]
    }
  ];

  const checkSubscriptionStatus = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PASARELA}/api/recurrente/sub_status`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        setSubscriptionData(data);
      } else {
        alert(response.error || 'Tu sesión expiro, inicia sesión de nuevo');
        setError('No se pudo obtener el estado de la suscripción');
      }
    } catch (error) {
      setError('Error de conexión');
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    if (!window.confirm('¿Estás seguro de que deseas cancelar tu suscripción?')) return;
    
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PASARELA}/api/recurrente/cancel_sub`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        await checkSubscriptionStatus();
      } else {
        setError('No se pudo cancelar la suscripción');
      }
    } catch (error) {
      setError('Error de conexión');
    } finally {
      setLoading(false);
    }
  };

  const initiateCheckout = async (planId) => {
    if (subscriptionData?.subscription?.isActive) {
      alert('Para cambiar de plan, primero debes cancelar tu suscripción actual.');
      return;
    }

    setLoading(true);
    setSelectedPlan(planId);
    setError(null);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_PASARELA}/api/recurrente/nuevocliente?desired_plan=${planId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success && data.checkout_url) {
          window.location.href = data.checkout_url;
        } else {
          setError('Respuesta de checkout inválida');
        }
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'No se pudo crear el checkout');
      }
    } catch (error) {
      setError('Error de conexión');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkSubscriptionStatus();
  }, [token]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="subscription-container">
      {loading && <LoadingState />}
      {error && (
        <div className="error-banner">
          <AlertCircle className="error-icon" />
          <p>{error}</p>
        </div>
      )}

      {subscriptionData?.subscription?.isActive && (
        <div className="current-plan-banner">
          <div className="banner-content">
            <CheckCircle2 className="success-icon" />
            <div className="banner-text">
              <h3>Plan Actual: {subscriptionData.subscription.plan}</h3>
              <p>Próxima facturación: {formatDate(subscriptionData.billing.nextBillingDate)}</p>
            </div>
            <button onClick={handleCancelSubscription} className="cancel-subscription-btn">
              Cancelar Suscripción
            </button>
          </div>
        </div>
      )}

      <div className="plans-section">
        <div className="section-header">
          <h2>Nuestros Planes</h2>
          <p>Elige el plan que mejor se adapte a tus necesidades</p>
        </div>

        <div className="plans-grid">
          {plans.map((plan) => (
            <div key={plan.id} className={`plan-card ${plan.colorClass} ${plan.popular ? 'popular' : ''}`}>
              {plan.popular && <div className="popular-tag">Más Popular</div>}
              <div className="plan-header">
                {plan.icon}
                <h3>{plan.name}</h3>
                <p className="plan-price">{plan.price}<span>/mes</span></p>
                <p className="plan-description">{plan.description}</p>
              </div>
              
              <ul className="feature-list">
                {plan.features.map((feature, index) => (
                  <li key={index}>
                    <CheckCircle2 className="feature-icon" />
                    {feature}
                  </li>
                ))}
              </ul>

              {plan.id !== 'test' && (
                <button
                  onClick={() => initiateCheckout(plan.id)}
                  disabled={loading && selectedPlan === plan.id}
                  className="select-plan-button"
                >
                  {loading && selectedPlan === plan.id ? (
                    'Procesando...'
                  ) : (
                    <>
                      Seleccionar Plan
                      <ArrowRight className="button-icon" />
                    </>
                  )}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      {subscriptionData && (
        <div className="user-section">
          <div className="user-profile">
            <img
              src={subscriptionData.user.picture || '/default-avatar.png'}
              alt="Profile"
              className="profile-picture"
            />
            <div className="user-info">
              <h3>{subscriptionData.user.name}</h3>
              <p>{subscriptionData.user.email}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewComponent;