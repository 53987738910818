import React, { useState, useEffect, useRef } from 'react';
import { UserAuth } from '../context/AuthContext';
import { 
  BookMarked, 
  Clock, 
  Search, 
  ChevronDown, 
  Trash2,
  ExternalLink,
  Filter,
  Type,
  RefreshCw,
} from 'lucide-react';
import DownloadDocxButton from './share/DW';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import ShareToWhatsApp from './share/ShareToWhatsApp';
import MarkupTextCopier from './pop/htmltomkd';
import ActionButtons from './pop/action';
const SavedQuestions = () => {
  const { token } = UserAuth();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedId, setExpandedId] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortBy, setSortBy] = useState('newest');
   const [fontSize, setFontSize] = useState(18);
   const [htmltext, setHtmlText] = useState('');
   const questionRefs = useRef({});
   const findScrollableParent = (element) => {
    if (!element) return document.documentElement;
    
    const style = window.getComputedStyle(element);
    const hasScroll = style.overflow === 'auto' || 
                    style.overflow === 'scroll' ||
                    style.overflowY === 'auto' ||
                    style.overflowY === 'scroll';
    
    if (hasScroll && element.scrollHeight > element.clientHeight) {
      return element;
    }
    
    return findScrollableParent(element.parentElement);
  };
   const handleQuestionDeleted = (deletedId) => {
    setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== deletedId));
  };
    // State for dynamic font family (default is your --body-font, e.g., Poppins)
    const [fontFamily, setFontFamily] = useState('Rumiko Clear');
    function markdownToWhatsApp(text) {
      let whatsappFormattedText = text;
      whatsappFormattedText = whatsappFormattedText.replace(/(\*\*|__)(.*?)\1/g, '*$2*');
      whatsappFormattedText = whatsappFormattedText.replace(/(\*|_)(.*?)\1/g, '_$2_');
      whatsappFormattedText = whatsappFormattedText.replace(/~~(.*?)~~/g, '~$1~');
      whatsappFormattedText = whatsappFormattedText.replace(/^(\s*)#+([^\n]*)/gm, '$1*$2*');
      whatsappFormattedText = whatsappFormattedText.replace(/(\[)(.*?)(\])/g, '_$2_');
      whatsappFormattedText = whatsappFormattedText.replace(/\* /g, '*');
      whatsappFormattedText = whatsappFormattedText.replace(/ \*\n/g, '*\n');
      whatsappFormattedText = whatsappFormattedText.replace(/_ /g, '_');
      whatsappFormattedText = whatsappFormattedText.replace(/ _\n/g, '_\n');
      return whatsappFormattedText;
    }

  useEffect(() => {
    fetchQuestions();
  }, []);
  useEffect(() => {
    if (expandedId && questionRefs.current[expandedId]) {
      setTimeout(() => {
        const element = questionRefs.current[expandedId];
        const scrollableParent = findScrollableParent(element);
        const elementRect = element.getBoundingClientRect();
        const parentRect = scrollableParent.getBoundingClientRect();
        
        // Calculate the scroll position to center the element
        const scrollTop = elementRect.top - parentRect.top - (parentRect.height - elementRect.height) / 4;
        
        scrollableParent.scrollTo({
          top: scrollableParent.scrollTop + scrollTop,
          behavior: 'smooth'
        });
      }, 100);
    }
  }, [expandedId]);

  const fetchQuestions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL_GUARDAR_PREGUNTA}/api/obtener_todas`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      
      const data = await response.json();
      if (data.success) {
        setQuestions(data.data);
      }
      else {
        // Alert for error from API response
        alert(data.error || 'Error al obtener las preguntas');
      }

    } catch (error) {
      console.error('Error fetching saved questions:', error);
      
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filteredQuestions = questions
    .filter(q => 
      q.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.content?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === 'newest') {
        return new Date(b.creation) - new Date(a.creation);
      } else {
        return new Date(a.creation) - new Date(b.creation);
      }
    });

  return (
    <div className="content-section">
      {/* Header Section */}
      <div className="content-wrapper-header2" style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <BookMarked size={24} style={{ color: '#3bf083' }} />
          <h2 style={{ margin: 0, color: 'var(--theme-color)' }}>
            Preguntas Guardadas
          </h2>
        </div>
      </div>

      {/* Search and Filter Section */}
      <div style={{ 
        display: 'flex', 
        gap: '15px', 
        marginBottom: '20px',
        background: 'var(--theme-bg-color)',
        padding: '15px',
        borderRadius: '10px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <div style={{ 
          display: 'flex', 
          flex: 1,
          background: 'rgba(59, 240, 131, 0.1)',
          borderRadius: '8px',
          padding: '8px 15px',
          alignItems: 'center'
        }}>
          <Search size={20} style={{ color: '#3bf083', marginRight: '10px' }} />
          <input
            type="text"
            placeholder="Buscar en preguntas guardadas..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              background: 'none',
              border: 'none',
              color: 'var(--theme-color)',
              width: '100%',
              outline: 'none'
            }}
          />
        </div>
        
        <button
          onClick={() => setFilterOpen(!filterOpen)}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            background: filterOpen ? 'rgba(59, 240, 131, 0.2)' : 'rgba(59, 240, 131, 0.1)',
            border: 'none',
            borderRadius: '8px',
            padding: '8px 15px',
            cursor: 'pointer',
            color: 'var(--theme-color)',
            transition: 'all 0.3s ease'
          }}
        >
          <Filter size={20} style={{ color: '#3bf083' }} />
          Ordenar
          <ChevronDown 
            size={16} 
            style={{ 
              transform: filterOpen ? 'rotate(180deg)' : 'none',
              transition: 'transform 0.3s ease'
            }} 
          />
        </button>
      </div>

      {/* Filter Dropdown */}
      {filterOpen && (
        <div style={{
          background: 'var(--theme-bg-color)',
          padding: '15px',
          borderRadius: '10px',
          marginBottom: '20px',
          boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)'
        }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              onClick={() => setSortBy('newest')}
              style={{
                background: sortBy === 'newest' ? '#3bf083' : 'rgba(59, 240, 131, 0.1)',
                color: sortBy === 'newest' ? '#000' : 'var(--theme-color)',
                border: 'none',
                borderRadius: '6px',
                padding: '8px 15px',
                cursor: 'pointer',
                transition: 'all 0.3s ease'
              }}
            >
              Más recientes
            </button>
            <button
              onClick={() => setSortBy('oldest')}
              style={{
                background: sortBy === 'oldest' ? '#3bf083' : 'rgba(59, 240, 131, 0.1)',
                color: sortBy === 'oldest' ? '#000' : 'var(--theme-color)',
                border: 'none',
                borderRadius: '6px',
                padding: '8px 15px',
                cursor: 'pointer',
                transition: 'all 0.3s ease'
              }}
            >
              Más antiguos
            </button>
          </div>
        </div>
      )}

      {/* Questions List */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        {filteredQuestions.map((question) => (
          <div
            key={question.id}
            
            style={{
              background: expandedId === question.id ? 'rgb(50, 54, 83)' : 'var(--theme-bg-color)',
              color: 'rgb(244,243,238)',
              borderRadius: '10px',
              padding: '20px',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              border: '1px solid rgba(59, 240, 131, 0.1)',
            }}
            onClick={() => setExpandedId(expandedId === question.id ? null : question.id)}
          >
            <div style={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'flex-start',
              marginBottom: expandedId === question.id ? '15px' : '0'
            }}>
              <div>
                <h3 ref={el => questionRefs.current[question.id] = el}
                style={{ 
                  margin: '0 0 8px 0',
                  color: 'var(--theme-color)',
                  fontSize: '1.1rem',
                  justifyContent: 'left'
                }}>
                  {question.title || 'Sin título'}
                </h3>
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: '8px',
                  color: 'var(--inactive-color)',
                  fontSize: '0.9rem'
                }}>
                  <Clock size={16} />
                  {formatDate(question.creation)}
                </div>
              </div>
              <ChevronDown 
                size={20} 
                style={{ 
                  color: '#3bf083',
                  transform: expandedId === question.id ? 'rotate(180deg)' : 'none',
                  transition: 'transform 0.3s ease'
                }} 
              />
            </div>

            {expandedId === question.id && (
              <div onClick={(e) => e.stopPropagation()}>
              <div style={{ 
       marginBottom: '1.5em',
       display: 'flex',
       alignItems: 'center',
       gap: '12px',
       padding: '5px',
       borderRadius: '8px',
       width:'20%'
     }}>
       <label 
         htmlFor="fontSizeSlider"
         style={{ 
           display: 'flex', 
           alignItems: 'center',
           gap: '8px',
           color: 'var(--theme-color)',
           fontWeight: '500'
         }}
       >
         <Type size={18} />
         Tamaño
       </label>
       <input
         id="fontSizeSlider"
         type="range"
         min="14"
         max="32"
         value={fontSize}
         onChange={(e) => setFontSize(e.target.value)}
         style={{ 
           flex: 1,
           accentColor: '#3bf083',
           height: '2px'
         }}
       />
       <button
         onClick={() => setFontSize(18)}
         style={{
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center',
           background: 'var(--theme-bg-color)',
           border: '1px solid rgba(59, 240, 131, 0.3)',
           borderRadius: '6px',
           padding: '6px',
           cursor: 'pointer',
           transition: 'all 0.2s ease',
           color: '#3bf083'
         }}
         onMouseOver={(e) => {
           e.currentTarget.style.transform = 'rotate(180deg)';
           e.currentTarget.style.backgroundColor = 'rgba(59, 240, 131, 0.1)';
         }}
         onMouseOut={(e) => {
           e.currentTarget.style.transform = 'rotate(0deg)';
           e.currentTarget.style.backgroundColor = 'var(--theme-bg-color)';
         }}
       >
         <RefreshCw size={16} />
       </button>
     </div>
     
     {/* Font Family Select */}
     <div style={{ 
       display: 'flex',
       alignItems: 'center',
       gap: '12px',
       paddingLeft: '12px',
       borderRadius: '8px',
       width: '20%'
     
     }}>
       <label 
         htmlFor="fontFamilySelect"
         style={{ 
           display: 'flex', 
           alignItems: 'center',
           gap: '8px',
           color: 'var(--theme-color)',
           fontWeight: '500'
         }}
       >
         <Type size={18} />
         Fuente
       </label>
       <div style={{ position: 'relative', flex: 1 }}>
         <select
           id="fontFamilySelect"
           value={fontFamily}
           onChange={(e) => setFontFamily(e.target.value)}
           style={{
             width: '100%',
             padding: '8px 12px',
             borderRadius: '6px',
             border: '1px solid rgba(59, 240, 131, 0.3)',
             background: 'var(--theme-bg-color)',
             color: 'var(--theme-color)',
             cursor: 'pointer',
             appearance: 'none', // Removes default select styling
             fontSize: '0.95rem'
           }}
         >
           <option value="var(--body-font)">Normal</option>
           <option value="OpenDyslexic">Para Dislexicos</option>
           <option value="Rumiko Clear">Rumiko</option>
         </select>
         <div style={{
           position: 'absolute',
           right: '12px',
           top: '50%',
           transform: 'translateY(-50%)',
           pointerEvents: 'none'
         }}>
           <ChevronDown size={16} color="#3bf083" />
         </div>
       </div>
     </div>
     <ActionButtons question={question} markdownToWhatsApp={markdownToWhatsApp} onQuestionDeleted={handleQuestionDeleted} />
               <div id="parrafo"
               style={{
                 fontSize: `${fontSize}px`,
                 fontFamily: fontFamily,
                 // The rest of your #parrafo styles can remain in App.css,
                 // or moved inline here. For example:
                 
               }}>
                 <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked.parse(question.content)) }}></p>
               </div>
               
             </div>
            )}
          </div>
        ))}
      </div>

      {/* Empty State */}
      {!loading && filteredQuestions.length === 0 && (
        <div style={{
          textAlign: 'center',
          padding: '40px 20px',
          color: 'var(--inactive-color)'
        }}>
          <BookMarked size={48} style={{ color: '#3bf083', marginBottom: '15px' }} />
          <h3>No hay preguntas guardadas</h3>
          <p>Las preguntas que guardes aparecerán aquí</p>
        </div>
      )}
    </div>
  );
};

export default SavedQuestions;