import React, { useState,useRef, useEffect } from 'react';
import { Copy, Share2, FileDown, MoreHorizontal, X, Save } from 'lucide-react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { asBlob } from 'html-docx-js-typescript';
import { UserAuth } from '../../context/AuthContext';

const ExtendedActionButtons = ({ textToSave, title: initialTitle, posicion = 'flex-end' }) => {
    const { token } = UserAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [copyStatus, setCopyStatus] = useState('');
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [saveTitle, setSaveTitle] = useState(initialTitle || '');
    const [saveStatus, setSaveStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const modalRef = useRef(null);
    const inputRef = useRef(null);
    useEffect(() => {
        if (showSaveModal) {
            setTimeout(() => {
                // Find the closest scrollable parent
                const findScrollableParent = (element) => {
                    if (!element) return document.documentElement;
                    
                    const style = window.getComputedStyle(element);
                    const hasScroll = style.overflow === 'auto' || 
                                    style.overflow === 'scroll' ||
                                    style.overflowY === 'auto' ||
                                    style.overflowY === 'scroll';
                    
                    if (hasScroll && element.scrollHeight > element.clientHeight) {
                        return element;
                    }
                    
                    return findScrollableParent(element.parentElement);
                };
    
                const scrollableParent = findScrollableParent(modalRef.current);
                scrollableParent.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
    
                // Focus the input field
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [showSaveModal]);
    const simplifyMarkup = (markup) => {
        if (!markup) return '';
        
        return markup
            .replace(/\*\*(.*?)\*\*/g, '$1')
            .replace(/\*(.*?)\*/g, '$1')
            .replace(/```(.*?)```/g, '$1')
            .replace(/`(.*?)`/g, '$1')
            .replace(/^\s*[-*+]\s/gm, '• ')
            .replace(/^\s*#{1,6}\s*/gm, '')
            .replace(/\n{3,}/g, '\n\n')
            .trim();
    };

    const handleSave = async () => {
        if (!saveTitle) {
            setSaveStatus('Se requiere un título');
            return;
        }

        setIsLoading(true);
        setSaveStatus('');

        try {
            const url = `${process.env.REACT_APP_API_BASE_URL_GUARDAR_PREGUNTA}/api/guardar`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    title: saveTitle,
                    content: textToSave
                })
            });

            const data = await response.json();

            if (data.success) {
                setSaveStatus('¡Guardado!');
                setShowSaveModal(false);
                setSaveTitle('');
                setTimeout(() => setSaveStatus(''), 2000);
            } else {
                setSaveStatus(data.error || 'Error al guardar');
            }
        } catch (error) {
            console.error('Error saving:', error);
            setSaveStatus('Error al guardar');
        } finally {
            setIsLoading(false);
            setIsMenuOpen(false);
        }
    };

    const actions = [
        {
            icon: <Save size={18} />,
            label: 'Guardar Pregunta',
            action: () => {
                setShowSaveModal(true);
                setIsMenuOpen(false);
            }
        },
        {
            icon: <Copy size={18} />,
            label: 'Copiar texto',
            action: () => {
                if (!textToSave) {
                    setCopyStatus('No hay texto para copiar');
                    setTimeout(() => setCopyStatus(''), 2000);
                    return;
                }
                const simplifiedText = simplifyMarkup(textToSave);
                navigator.clipboard.writeText(simplifiedText)
                    .then(() => {
                        setCopyStatus('¡Copiado!');
                        setTimeout(() => setCopyStatus(''), 2000);
                        setIsMenuOpen(false);
                    });
            }
        },
        {
            icon: <Share2 size={18} />,
            label: 'Compartir vía WhatsApp',
            action: () => {
                if (!textToSave) {
                    setCopyStatus('No hay texto para compartir');
                    setTimeout(() => setCopyStatus(''), 2000);
                    return;
                }
                const text = textToSave.replace(/<br\s*\/?>/gi, '\n')
                    .replace(/<div\s*\/?>/gi, '\n')
                    .replace(/<\/div\s*\/?>/gi, '\n')
                    .replace(/&nbsp;/g, ' ');
                const whatsappText = `*${saveTitle || 'Compartido desde Temis'}*:\n${text}`;
                const encodedText = encodeURIComponent(whatsappText + "\n\nDesbloquea el mundo del derecho con Temis: https://www.temisgpt.io");
                window.open(`https://wa.me/?text=${encodedText}`, '_blank', 'noopener,noreferrer');
                setIsMenuOpen(false);
            }
        },
        {
            icon: <FileDown size={18} />,
            label: 'Descargar como Word',
            action: () => {
                if (!textToSave) {
                    setCopyStatus('No hay texto para descargar');
                    setTimeout(() => setCopyStatus(''), 2000);
                    return;
                }
                const htmlContent = `<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>${DOMPurify.sanitize(marked.parse(textToSave))}</body></html>`;
                asBlob(htmlContent).then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = `${saveTitle || 'documento'}.docx`;
                    document.body.appendChild(anchor);
                    anchor.click();
                    document.body.removeChild(anchor);
                    window.URL.revokeObjectURL(url);
                });
                setIsMenuOpen(false);
            },
            mobileWarning: "Descargar Word solamente funciona en PC debido al formato del documento generado"
        }
    ];

    return (
        <div style={{ position: 'relative', display: 'flex', justifyContent: posicion || 'flex-end' }}>
            <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                style={{
                    background: 'rgba(59, 240, 131, 0.1)',
                    border: 'none',
                    borderRadius: '8px',
                    padding: '10px',
                    cursor: 'pointer',
                    color: '#3bf083',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    transition: 'all 0.2s ease',
                    fontSize: '20px'
                  }}
            >
                {isMenuOpen ? <X size={20} /> : <>
                    <MoreHorizontal size={20} />
                    <span>Opciones</span>
                </>}
            </button>

            {isMenuOpen && (
                <div
                    style={{
                        position: 'absolute',
                        bottom: '100%',
                        right: '0',
                        marginBottom: '8px',
                        background: 'black',
                        borderRadius: '12px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                        padding: '8px',
                        minWidth: '200px',
                        zIndex: 1000
                    }}
                >
                    {actions.map((action, index) => (
                        <div key={index}>
                            <button
                                onClick={action.action}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '12px',
                                    width: '100%',
                                    fontSize: '18px',
                                    padding: '12px',
                                    border: 'none',
                                    background: 'none',
                                    color: 'var(--theme-color)',
                                    cursor: 'pointer',
                                    borderRadius: '8px',
                                    transition: 'all 0.2s ease'
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.background = 'rgba(59, 240, 131, 0.1)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.background = 'none';
                                }}
                            >
                                <span style={{ color: '#3bf083' }}>{action.icon}</span>
                                {action.label}
                            </button>
                            {action.mobileWarning && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && (
                                <p style={{
                                    margin: '0',
                                    padding: '4px 12px',
                                    fontSize: '0.8rem',
                                    color: 'var(--inactive-color)'
                                }}>
                                    {action.mobileWarning}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            )}
            
            {showSaveModal && (
                <div className="modal-overlay" onClick={(e) => {
                    if (e.target.className === 'modal-overlay') {
                        setShowSaveModal(false);
                    }
                }}>
                    <div className="modal-content" ref={modalRef}>
                        <h2>Guardar Pregunta</h2>
                        <input
                        ref={inputRef}
                            type="text"
                            value={saveTitle}
                            onChange={(e) => setSaveTitle(e.target.value)}
                            placeholder="Ingrese un título"
                            className="modal-input"
                        />
                        <div className="modal-buttons">
                            <button
                                className="content-button"
                                onClick={() => setShowSaveModal(false)}
                                disabled={isLoading}
                            >
                                Cancelar
                            </button>
                            <button
                                className="content-button status-button"
                                onClick={handleSave}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Guardando...' : 'Guardar'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {copyStatus && (
                <div style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    background: '#3bf083',
                    color: '#000',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    animation: 'fadeIn 0.3s ease'
                }}>
                    {copyStatus}
                </div>
            )}

            {saveStatus && (
                <div style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    background: '#3bf083',
                    color: '#000',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    animation: 'fadeIn 0.3s ease'
                }}>
                    {saveStatus}
                </div>
            )}

            <style jsx>{`
                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;
                }

                 .modal-content {
                    background: white;
                    padding: 20px;
                    border-radius: 8px;
                    width: 90%;
                    max-width: 500px;
                    position: relative;
                    top: -20vh; /* This moves the modal up by 20% of the viewport height */
                }

                .modal-input {
                    width: 100%;
                    padding: 8px;
                    margin: 10px 0;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                }

                .modal-buttons {
                    display: flex;
                    justify-content: center;
                    gap: 10px;
                    margin-top: 20px;
                }

                h2 {
                    margin: 0;
                    color: #333;
                }

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(10px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            `}</style>
        </div>
    );
};

export default ExtendedActionButtons;