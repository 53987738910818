import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import AlertPopup from '../AlertPopup';
import { useSidebar } from './context/SidebarContext';

const ProtectedLayout = () => {
  const { logout, fetchNotifications } = UserAuth();
  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 944);
  const [localNotifications, setLocalNotifications] = useState([]);
  const [isNotificationPopupVisible, setIsNotificationPopupVisible] = useState(false);
  const { sidebarContent } = useSidebar();
  //const hasSidebarContent = sidebarContent && sidebarContent.props.children && React.Children.count(sidebarContent.props.children) > 0;


  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/signin');
    } catch (e) {
      alert(e.message);
    }
  };

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 944);
  };

  const handleClickOutside = (event) => {
    if (isMobileView && isSideMenuVisible && !document.querySelector('.left-side').contains(event.target) && !document.querySelector('.hamburger-menu').contains(event.target)) {
      setIsSideMenuVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSideMenuVisible]);

  useEffect(() => {
    const getNotifications = async () => {
      const fetchedNotifications = await fetchNotifications();
      setLocalNotifications(fetchedNotifications);
    };

    getNotifications();
  }, []);

  const handleNotificationClick = () => {
    setIsNotificationPopupVisible(true);
  };
 

  return (
    <div className="wrapper" style={{ height: 'auto' }}>
      {isNotificationPopupVisible && (
        <AlertPopup
          title={localNotifications[0]?.titulo || ''}
          message={localNotifications[0]?.texto || ''}
          onClose={() => setIsNotificationPopupVisible(false)}
        />
      )}
      <div className='hamburger-menu' onClick={() => setIsSideMenuVisible(!isSideMenuVisible)}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div
        className={`left-side ${isSideMenuVisible ? 'show floating' : ''} ${isMobileView ? 'absolute' : 'static'}`} 
        style={{
          zIndex: 2,
          flexBasis: 'auto',
          backgroundColor: 'initial',
          backdropFilter: 'none',
          /*if mbile vew change max height*/
          maxHeight: isMobileView ? '80vh' : 'auto'
         
        }}
      >
        <div className='side-wrapper'
        /*style height auto*/
        
        >
          <div className="header-profile">
            <div className="notification" onClick={handleNotificationClick}>
              <svg viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell">
                <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 01-3.46 0"></path>
              </svg>
            </div>
          </div>
          <div className='side-menu'>
            <button onClick={handleLogout} className="content-button status-button open">Logout</button>
            <button onClick={() => navigate('/account/Temis')} className="content-button status-button open">Temis</button>
            <button onClick={() => navigate('/account/politica')} className="content-button status-button open">Politica</button>
            <button onClick={() => navigate('/account/manual')} className="content-button status-button open">Manual</button>
            <button onClick={() => navigate('/account/new')} className="content-button status-button open">Planes</button>
            <button onClick={() => navigate('/account/guardado')} className="content-button status-button open">Guardado</button>
            {sidebarContent}
            
            <div className="content-section-title">_</div>
          </div>
          
         
        </div>
        
      </div>
      <div className={`content-section ${isSideMenuVisible ? 'with-side' : ''}`}>
        <Outlet /> {/* This is where the nested routes will render */}
      </div>
      {(isNotificationPopupVisible || (isMobileView && isSideMenuVisible)) && (
        <div 
          className="overlay-app is-active"
          onClick={() => setIsSideMenuVisible(false)}
        ></div>
      )}
      
    </div>
  );
};

export default ProtectedLayout;
